import { Link } from 'react-router-dom';
import React from "react";

export const COLUMNS = [
    {
        Header: "Log ID",
        accessor: "_id",
    },
    {
        Header: "Created At",
        accessor: "created_at",
        Cell: ({ value }) => new Date(value).toLocaleString(),
    },
    {
        Header: "Program ID",
        accessor: "program_id",
        Cell: ({ row, value  }) => (
          <Link to={`/program/view/${value}`}>{row.original.program_name}</Link>
        ),
    },
    {
        Header: "Entrata Property Id",
        accessor: "entrata_property_id",
    },
    {
        Header: "File type",
        accessor: "file_type",
        Cell: ({ row, value }) => {
            let val = '';
            if (value === 1){
                val = 'Customers';
            } else if (value === 2) {
                val = 'Leases';
            } else if (value === 3) {
                val = 'Leads';
            } else if (value === 4) {
                val = 'Reports';
            }
            return val;
        }
    },
    {
        Header: "Status",
        accessor: "status",
        Cell: ({ row, value  }) => (
          <div style={{ color: value === 1 ? 'green' : 'red' }}>
              {value ? 'Success' : 'Error' }
          </div>
        ),
    },
]

