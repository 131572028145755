import React, {FC, useEffect, useRef, useState} from 'react';
import { Card, CardBody, Col } from 'reactstrap';
// import {defFilter}from "./SupplierRedemptionSubFilter";
import SupplierRedemptionTable from "../../../../../Reports/SupplierRedemption/components/SupplierRedemptionTable";
import axios from "axios";
import {isEmpty} from '@/shared/helpers'
import {CSVLink} from "react-csv";

interface SupplierRedemptionIndexProps
{
    programid?: any
}

const SupplierRedemptionIndex: FC<SupplierRedemptionIndexProps> = ({programid}) => {
    const [merchants, setMerchants] = useState([]);
    const [defaultMerchants, setDefaultMerchants] = useState([]);

    const getData = async () => {
        const merchantsApiUrl = `/organization/1/merchant?page=0&limit=9999999999&minimal=1`
        if (isEmpty(merchants)) {
            try {
                const response = await axios.get(merchantsApiUrl);
                if (response.data.length === 0) return {results: [], count: 0}
                const data = response.data;
                setMerchants(data);
                return data;
            } catch (e) {

            }
        }
    }

    useEffect(() => {
        getData();
        if (merchants) {
            const result = merchants.map(x => x.id)
            setDefaultMerchants(result);
        }
    }, [merchants])

    if (isEmpty(defaultMerchants)) {
        return <p>Loading...</p>;
    }

    return (
        <Col md={12}>
            <Card>
                <CardBody>
                    <SupplierRedemptionTable merchants={defaultMerchants}/>
                </CardBody>
            </Card>
        </Col>
    )
}

export default SupplierRedemptionIndex;
