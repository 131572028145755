
import axios from 'axios'

const getCsvImportSettings = async (organizationId, id) => {
    try {
        const url = `/organization/${organizationId}/csv-import-setting/${id}/show`;
        const response = await axios.get(url);
        return response.data;
    } catch (e) {
        throw new Error(`API error:${e?.message}`);
    }
}
export default getCsvImportSettings
