import React from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import ImportSourceFilesList from "./components/ImportSourceFilesList";

const ImportSourceFiles = ({organization}) => {

    let { id } = useParams();

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">Imports</h3>
            <h3 className="page-subhead subhead">
                <Link className="" to="/">Home</Link> &nbsp; / &nbsp;
                <Link className="" to="/import/source">Import Sources</Link> &nbsp; / &nbsp;
                <Link className="" to={`/import/source/edit/${id}`}>{id}</Link> &nbsp; / &nbsp; Remote Files</h3>
          </Col>
        </Row>
        <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <ImportSourceFilesList organization={organization}/>
                </CardBody>
              </Card>
            </Col>
        </Row>
      </Container>
    )
}

export default withRouter(connect((state) => ({
  theme: state.theme,
  rtl: state.rtl,
  organization: state.organization
}))(ImportSourceFiles));
