import React, { useState, useEffect} from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    Modal,
    ModalBody,
    ModalHeader,
    Button,
    ButtonToolbar,
    Row,
    Col,
} from "reactstrap";
import { Form, Field } from "react-final-form";
import axios from "axios";
import PropTypes from 'prop-types';
import { sendFlashMessage } from "@/shared/components/flash";
import { getProgramAction } from "@/redux/actions/programActions";
import getEntrataConnection from '@/service/program/getEntrataConnection';
import AlertPopup from '@/shared/components/modal/alertModal/AlertPopup';
import getUserStatusList from '@/service/getUserStatuses';


const EntrataModal = ({ isOpen, toggle, program, userTypes, dispatch, organization }) => {
    const [loading, setLoading] = useState(true);
    const [userStatusLoading, setUserStatusLoading] = useState(true);
    const [connectionStatus, setConnectionStatus] = useState(null);

    const [entrataSettings, setEntrataSettings] = useState(null);
    const [userStatuses, setUserStatuses] = useState([]);

    const [alertMessage, setAlertMessage] = useState('')
    const [isAlertOpen, setIsAlertOpen] = useState(false)

    useEffect( () => {
        if( organization?.id )
        {
            getEntrataConnection( organization.id, program.id )
            .then( data => {
                setEntrataSettings(data);
                setLoading(false);
            })
        }
    }, []);

    useEffect( () => {
        if( organization?.id )
        {
            getUserStatusList( organization.id )
                .then(list => {
                    setUserStatuses([
                        ...list.map(status => ({ value: status.id, label: status.status }))
                    ]);
                    setUserStatusLoading(false);
                });
        }
    }, [entrataSettings]);

    const onSubmitForm = async (values) => {
        setLoading(true);
        try {
            if(entrataSettings.id){
                const response = await axios.put(`/entrata/${entrataSettings.id}`, values);
                setLoading(false);
                if (response.status === 200) {
                    setEntrataSettings(response.data);
                    dispatch(sendFlashMessage("Entrata configuration has been updated", "alert-success", "top"));
                    dispatch(getProgramAction(organization.id, program.id));
                    toggle(); // Close the modal on success
                }
            }else{
                const response = await axios.put(`/organization/${organization.id}/program/${program.id}/entrata`, values);
                setLoading(false);
                if (response.status === 200){
                    setEntrataSettings(response.data);
                    dispatch(sendFlashMessage("Entrata configuration has been created", "alert-success", "top"));
                    dispatch(getProgramAction(organization.id, program.id));
                    toggle(); // Close the modal on success
                }else{
                    dispatch(sendFlashMessage("Entrata configuration could not be created", "alert-danger", "top"));
                    dispatch(getProgramAction(organization.id, program.id));
                    toggle(); // Close the modal on success
                }
            }

        } catch (error) {
            setLoading(false);
            dispatch(sendFlashMessage("Entrata configuration could not be updated", "alert-danger", "top"));
            console.error(`API error: ${error.message}`);
        }
    };

    const verifyConnection = async (values) => {
        if(!entrataSettings.id){
            dispatch(sendFlashMessage("Entrata configuration does not exist", "alert-danger", "top"));
        }else{
            try {
                const response = axios.get(`/entrata/verify/${entrataSettings.id}`)
                  .then( (response) => {
                      setAlertMessage(response.data.msg);
                      alertToggle();
                  })
                  .catch( error => {
                      console.log(error);
                  })
            } catch (error) {
                console.log(error)
            }
        }
    };

    const alertToggle = () => {
        setIsAlertOpen(prevState => !prevState)
    }

    if( loading || userStatusLoading) return 'Loading...'

    return (

        <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
            <AlertPopup isOpen={isAlertOpen} title="Verify connection" message={alertMessage} toggle={alertToggle} />
            <Form
                onSubmit={onSubmitForm}
                initialValues={entrataSettings}
            >
                {({ handleSubmit, values }) => (
                    <form onSubmit={handleSubmit}>
                        <ModalHeader toggle={toggle} className='w100' >
                            <Row className='w100'>
                                <Col md="6" lg="6" xl="6" className='text-left'>
                                    Entrata Configurations
                                </Col>
                                <Col md="6" lg="6" xl="6" className='text-right'>
                                  <ButtonToolbar className="modal__footer flex justify-content-right w100">
                                    <Button outline color="secondary" onClick={toggle} className="mr-3">
                                        Close
                                    </Button>
                                    {' '}
                                    <Button
                                        type="submit"
                                        disabled={loading}
                                        color="primary" >
                                        Save Changes
                                    </Button>
                                  </ButtonToolbar>
                                </Col>
                            </Row>
                        </ModalHeader>
                        <ModalBody>
                            <fieldset>
                                <legend>Credentials</legend>
                                <Row>
                                    <Col md="6">
                                        <div className="form-group">
                                            <label htmlFor="url">URL</label>
                                            <Field
                                                name="url"
                                                component="input"
                                                type="text"
                                                className="form-control"
                                                initialValue={entrataSettings.url}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="form-group">
                                            <label htmlFor="username">Username</label>
                                            <Field
                                                name="username"
                                                component="input"
                                                type="text"
                                                className="form-control"
                                                initialValue={entrataSettings.username}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <div className="form-group">
                                            <label htmlFor="entrata_property_id">Entrata Property ID</label>
                                            <Field
                                                name="entrata_property_id"
                                                component="input"
                                                type="text"
                                                className="form-control"
                                                initialValue={entrataSettings.entrata_property_id}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="form-group">
                                            <label htmlFor="password">Password</label>
                                            <Field
                                                name="password"
                                                component="input"
                                                type="password"
                                                className="form-control"
                                                initialValue={entrataSettings.password}
                                            />
                                        </div>
                                    </Col>

                                    <Col md="6">
                                        <div className="form-group">
                                            <label htmlFor="user_status_id">User Status</label>
                                            <Field
                                                name="user_status_id"
                                                component="select"
                                                className="form-control"
                                                initialValue={entrataSettings.user_status_id}
                                            >
                                                <option value="">Select Status</option>
                                                {userStatuses.map((status) => (
                                                    <option value={status.value}>
                                                        {status.label}
                                                    </option>
                                                ))}
                                            </Field>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <div className="form-group">
                                            <Button
                                                type="button"
                                                color="primary"
                                                onClick={() => verifyConnection(values)}
                                                disabled={loading}
                                            >
                                                Verify Connection
                                            </Button>
                                            {connectionStatus && (
                                                <p className={connectionStatus === "Connection successful" ? "text-success" : "text-danger"}>
                                                    {connectionStatus}
                                                </p>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </fieldset>
                        </ModalBody>
                    </form>
                )}
            </Form>
        </Modal>
    );
};

EntrataModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    program: PropTypes.object.isRequired,
    userTypes: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    organization: PropTypes.object.isRequired,
};

export default withRouter(
    connect((state) => ({
        organization: state.organization,
        userTypes: state.userTypes,
        program: state.program,
    }))(EntrataModal)
);
