import React, { useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getProgram } from '@/service/program/getProgram';
const ProgramBreadcrumb = ({organization, program, linkSelf=false}) => {
  const [programHierarchy, setProgramHierarchy] = useState(null);
  useEffect(() => {
    if(organization && organization?.id && program && program?.id ) {
      // delete program.hierarchy
      if( program?.hierarchy )   {
        setProgramHierarchy(program.hierarchy)
      } else  {
        getProgram(organization.id, program.id, {'hierarchy':true, 'hierarchyOnly':true})
        .then(programH => {
          setProgramHierarchy(programH)
        })
        .catch(e => {
            throw new Error(`API error:${e?.message}`);
        });
      }
    }
  },[organization, program])
  if( !programHierarchy || programHierarchy.length <=0 ) return null;
  return  programHierarchy.map((p)=> <span key={p.id}  > / <Link className="" style={{color : (p.id === program.id && !linkSelf) ? "grey":"#70bbfd", pointerEvents: (p.id === program.id && !linkSelf) ? "none":"auto"}} to={`/program/view/${p.id}`}  >{p.name}</Link></span>
  )
}
export default withRouter(connect((state) => ({
  organization: state.organization
}))(ProgramBreadcrumb));