import React, {useState} from 'react';
import {
  Modal,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';

const ConfirmPopup = ({message, action, isOpen, setOpen, toggle}) => {
  const createMarkup = (value) => {
    return {__html: value};
  }

  if (!message){
    message = 'Are you sure you want to delete this item? <br/> WARNING: This cannot be undone!'

  }

  return (
    <Modal className={`confirm-modal`} isOpen={isOpen} toggle={toggle}>
      <Card className=''>
        <CardBody>
          <div className={`message`} dangerouslySetInnerHTML={createMarkup(message)}/>
          <p>&nbsp;</p>
          <div className='d-flex justify-content-end'>
            <Button color='primary' type='submit' onClick={action}>Ok</Button>&nbsp;
            <Button color='primary' type='submit' onClick={toggle}>No</Button>
          </div>
        </CardBody>
      </Card>
    </Modal>
  )
}

export default ConfirmPopup;
