import React, {useState, useEffect} from 'react';
import { Form, Field } from 'react-final-form';
import CheckboxField from '@/shared/components/form/CheckBox';
import { Row, Col, ButtonToolbar, Button } from 'reactstrap';
import formValidation from "@/shared/validation/imports/addImportSource";
import { useParams} from 'react-router-dom'
import renderSelectField from '@/shared/components/form/Select';
import axios from 'axios';
import {mapFormDataUploads} from '@/shared/helpers'
import {useDispatch, sendFlashMessage} from "@/shared/components/flash"
import {SOURCE_OPTIONS} from "./SourceOptions";

const fetchImportSource = async ( id, organization ) => {
    try {
        const response = await axios.get(`/organization/${organization.id}/importsource/${id}`);
        return response.data;
    } catch (e) {
        throw new Error(`API error:${e?.message}`);
    }
};

const ImportSourceForm = ({organization}) => {

    const dispatch = useDispatch()

    let { id } = useParams();

    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState(null)
    const [importSource, setImportSource] = useState({})

    useEffect( ()=>{
        if(id){
            setLoading(true)
            fetchImportSource( id, organization )
            .then( response => {
                setImportSource(response.data);
                setLoading(false);
            })
        }
    }, [id, organization])

    if(id && loading){
        return <p>Loading...</p>;
    }

    const List = ({errors}) => {
        return (
            <ul className="apiError-list">
                {
                    Object.keys(errors).map(function(k){
                        return <li key={k}  className="apiError-listItem">{errors[k]}</li>
                    })
                }
            </ul>
        )
    }

    const onSubmitForm = async (values) => {

        setLoading(true);
        Object.keys(values).forEach(k => {
            if(k == 'status' || k == 'is_regex' ){
                values[k]=values[k]==true ? 1 : 0;
            }
        });
        const data = mapFormDataUploads( values );
        axios.post(`/organization/${organization.id}/importsource`, data)
        .then( (res) => {
          if(res.status == 200)  {
            window.location = `/import/source?message=New Import Source added successfully!`
          }
        })
        .catch( error => {
            setErrors(error.response.data.errors);
            //dispatch(sendFlashMessage(JSON.stringify(error.response.data.errors), 'alert-danger'))
            // throw new Error(`API error:${e?.message}`);
            setLoading(false)
        })
    }


    const onClickCancel = () => {
        window.location = `/import/source`
    }

    return (
    <Form
        onSubmit={onSubmitForm}
        //validate={(values) => formValidation.validateForm(values)}
        initialValues={importSource}
    >
    {({ handleSubmit, form, submitting, pristine, values }) => (
    <form className="form" onSubmit={handleSubmit}>
        {errors &&
            <div className="alert alert-danger fade show w100 mb-4" role="alert">
                <div className="alert__content"><List errors={errors} /></div>
            </div>
        }
        <Row className='w100'>
            <Col md="6" lg="6" xl="6">
                <h3 className="mb-4">{id?'Edit Import Source':'Add New Import Source'}</h3>
            </Col>
            <Col md="6" lg="6" xl="6" className='text-right'>
                <ButtonToolbar className="modal__footer flex justify-content-right w100">
                    <Button outline color="primary" className="mr-3" onClick={onClickCancel}>Cancel</Button>{' '}
                    <Button type="submit" disabled={loading || pristine} className="btn btn-primary" color="#ffffff">Save</Button>
                </ButtonToolbar>
            </Col>
        </Row>
        <Row>
            <Col md="6" lg="4" xl="4">
                <Field name="display_name">
                {({ input, meta }) => (
                    <div className="form__form-group">
                        <span className="form__form-group-label">Display Name</span>
                        <div className="form__form-group-field">
                            <div className="form__form-group-row">
                                <input type="text" {...input} placeholder="Display Name" />
                                {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
                            </div>
                        </div>
                    </div>
                )}
                </Field>
                <div className="form__form-group">
                    <span className="form__form-group-label">Source Type</span>
                    <div className="form__form-group-field">
                        <Field
                            name="source_type"
                            component={renderSelectField}
                            options={SOURCE_OPTIONS}
                            initialValue={importSource.source_type}
                            />
                    </div>
                </div>
                <Field name="host">
                {({ input, meta }) => (
                    <div className="form__form-group">
                        <span className="form__form-group-label">Host</span>
                        <div className="form__form-group-field">
                            <div className="form__form-group-row">
                                <input type="text" {...input} placeholder="Host" />
                                {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
                            </div>
                        </div>
                    </div>
                )}
                </Field>
                <Field name="user_name">
                    {({ input, meta }) => (
                        <div className="form__form-group">
                            <span className="form__form-group-label">User</span>
                            <div className="form__form-group-field">
                                <div className="form__form-group-row">
                                    <input type="text" {...input} placeholder="User" />
                                    {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
                                </div>
                            </div>
                        </div>
                    )}
                </Field>
                <Field name="user_password">
                    {({ input, meta }) => (
                        <div className="form__form-group">
                            <span className="form__form-group-label">Password</span>
                            <div className="form__form-group-field">
                                <div className="form__form-group-row">
                                    <input type="text" {...input} placeholder="Password" />
                                    {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
                                </div>
                            </div>
                        </div>
                    )}
                </Field>
                <Field name="local_directory">
                    {({ input, meta }) => (
                        <div className="form__form-group">
                            <span className="form__form-group-label">Local Directory</span>
                            <div className="form__form-group-field">
                                <div className="form__form-group-row">
                                    <input type="text" {...input} placeholder="Local Directory" />
                                    {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
                                </div>
                            </div>
                        </div>
                    )}
                </Field>
                <Field name="remote_directory">
                    {({ input, meta }) => (
                        <div className="form__form-group">
                            <span className="form__form-group-label">Remote Directory</span>
                            <div className="form__form-group-field">
                                <div className="form__form-group-row">
                                    <input type="text" {...input} placeholder="Remote Directory" />
                                    {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
                                </div>
                            </div>
                        </div>
                    )}
                </Field>
                <div className="form__form-group">
                    <Field
                        name="is_regex"
                        type="checkbox"
                        component={CheckboxField}
                        label="Is Regex"
                        initialValue={importSource.is_regex}
                      />
                </div>
                <Field name="file_name_like">
                    {({ input, meta }) => (
                        <div className="form__form-group">
                            <span className="form__form-group-label">File Name Like</span>
                            <div className="form__form-group-field">
                                <div className="form__form-group-row">
                                    <input type="text" {...input} placeholder="File Name Like" />
                                    {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
                                </div>
                            </div>
                        </div>
                    )}
                </Field>
                <div className="form__form-group">
                    <Field
                        name="status"
                        type="checkbox"
                        component={CheckboxField}
                        label="Active"
                        initialValue={importSource.status}
                      />
                </div>
            </Col>
        </Row>
    </form>
    )}
  </Form>
)}

export default ImportSourceForm;
