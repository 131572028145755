import React, {useEffect, useState} from 'react';
import {Link, useParams, withRouter} from 'react-router-dom';
import {Card, CardBody, Col, Container, Row} from 'reactstrap';
import ImportViewCard from './components/ImportView';
import {IMPORT_DATA} from "./components/MockData";
import {connect, useDispatch} from "react-redux";
import axios from "axios";



const ImportView = ({organization}) => {

  const { id } = useParams()
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true) //first page load!
  const [importCsv, setImportCsv] = useState(null)

  const fetchImportCsv = async ( id, organization ) => {
    try {
      const response = await axios.get(`/organization/${organization.id}/import/view/${id}`);
      console.log(response);
      return response.data;
    } catch (e) {
      throw new Error(`API error:${e?.message}`);
    }
  };

  useEffect( ()=>{
    fetchImportCsv( id, organization )
      .then( response => {
        setImportCsv(response);
        setIsLoading(false)
      })
  }, [id, organization])


  if (isLoading || !importCsv) {
    return <p>Loading...</p>;
  }
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Imported File</h3>
          <h3 className="page-subhead subhead">
            <Link className="" to="/">Home</Link>
            &nbsp;/ <Link className="" to="/import/list">Files</Link>
            &nbsp;/ {importCsv.name}
          </h3>
        </Col>
      </Row>
      <Row>
        <ImportViewCard item={importCsv} />
      </Row>
    </Container>
  )
}

export default withRouter(connect((state) => ({
  organization: state.organization
}))(ImportView));

