import {SOURCE_OPTIONS} from "./SourceOptions";
import { Link } from 'react-router-dom';

export const IMPORT_SOURCE_COLUMNS = [
    {
        Header: "#",
        accessor: "id",
        Footer:"#",
        disableFilters:true,
        disableGlobalFilters:true,
        width: 60
    },
    {
        Header: "Display Name",
        accessor: "display_name"
    },
    {
      Header: "Source Type",
      accessor: "source_type",
      Cell: ({ row, value }) => {
          var v = '';
          SOURCE_OPTIONS.forEach( record => {
              if(record.value == row.original.source_type){
                  v = record.label;
              }
          });

          return v;
      }
    },
    /*
    {
        Header: "Program",
        accessor: "program_id",
        Cell: ({ row, value }) => { return <a href={`${process.env.REACT_APP_API_STORAGE_URL}/${row.original.path}`}>{value}</a>},
    },
    */
    {
        Header: "Bucket / Remote Directory",
        accessor: "remote_directory"
    },
    {
      Header: "Path to Local Directory",
      accessor: "local_directory"
    },
    {
      Header: "Active",
      accessor: "status",
      Cell: ({ row, value }) => value ? 'Yes' : 'No',
    },
    {
      Header: "Action",
      accessor: "",
      Cell: ({ row, value }) =>
              <>
                <Link to={`/import/source/edit/${row.original.id}`}>Edit</Link>
                &nbsp; | &nbsp;
                <Link to={`/import/source/files/${row.original.id}`}>Files</Link>
              </>
    }
]
