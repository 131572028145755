import React from 'react';
import { Link } from 'react-router-dom';
import {Card, CardBody, Col, Container, Row} from 'reactstrap';
import ImportSourceDataTable from './components/ImportSourceDataTable';

const ImportSource = () => {

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">All Import Sources</h3>
          <h3 className="page-subhead subhead">
            <Link className="" to="/">Home</Link>
            / Import Sources
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <Row>
                <Col md={12}>
                    <Link style={{maxWidth:'200px'}}
                        className="btn btn-success account__btn account__btn--small"
                        to="/import/source/add"
                        >Add new import source
                    </Link>
                </Col>
              </Row>
              <ImportSourceDataTable/>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
)}

export default ImportSource;

