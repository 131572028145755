import React, { useState} from 'react'
import {connect} from 'react-redux'
import MerchantsHierarchy from '@/shared/components/MerchantsHierarchy'
import {Button, Col, Row} from "reactstrap";
import DatePicker from "react-datepicker";
import {CSVLink} from "react-csv";
import {getFirstDay} from '@/shared/helpers'
import {dateStrToYmd} from '@/shared/helpers';
import {isEqual, clone, cloneDeep} from 'lodash';
import {formatCurrency} from '@/shared/helpers'
import RadioButtonField from '@/shared/components/form/RadioButton';
import ToggleButtonField from '@/shared/components/form/ToggleButton';
import Select from 'react-select'

const defaultFrom = getFirstDay()
const defaultTo = new Date()
const inventoryTypes = [
    { 'label': 'All Codes', 'value': 0 },
    { 'label': 'Real Codes', 'value': 1 },
    { 'label': 'Virtual Codes', 'value': 2 },
];

const SupplierRedemptionFilter = (
    {
      filter,
      setFilter,
      setUseFilter,
      download,
      exportData,
      exportLink,
      exportHeaders
    }) => {
  const options = {
    'dateRange': true,
    'merchants': true,
    'exportToCsv': true,
    'active': true,
    'reportKey': true,
    'inventoryType': true,
  }
  const finalFilter = {...filter}
  const [from, setFrom] = React.useState(defaultFrom)
  const [to, setTo] = React.useState(defaultTo)
  const [active, setActive] = React.useState(true)
  const [inventoryType, setInventoryType] = React.useState(finalFilter.inventoryType)
  const [reportKey, setReportKey] = React.useState('sku_value')
  const [selectedMerchants, setSelectedMerchants] = useState(filter.merchants ? filter.merchants : []);
  let previous = cloneDeep(finalFilter);

  const onClickFilter = (reset = false, exportToCsv = 0) => {
    let dataSet = {}

    if (options.merchants) {
        dataSet.merchants = reset ? [] : clone(selectedMerchants);
    }
    if (options.dateRange) {
      dataSet.from = dateStrToYmd(reset ? defaultFrom : from)
      dataSet.to = dateStrToYmd(reset ? defaultTo : to)
    }
    if (options.reportKey) {
      dataSet.reportKey = reset ? 'sku_value' : reportKey
    }
    if (options.active) {
        dataSet.active = reset ? true : active
    }
    if (options.inventoryType) {
        dataSet.inventoryType = reset ? 0 : inventoryType
    }
    
    onClickFilterCallback(dataSet)
    previous = dataSet;
    if (reset) {
      setFrom(defaultFrom)
      setTo(defaultTo)
      setActive(true)
      setReportKey('sku_value')
    }
  }

  const onClickFilterCallback = (values) => {
    let change = false;

    if (options.merchants) {
      if (!isEqual(values.merchants, previous.merchants)) {
        change = true
      }
    }

    if (options.dateRange) {
      if (finalFilter.from !== values.from || finalFilter.to !== values.to) {
        change = true
      }
    }
    if (options.active) {
      if (finalFilter.active !== values.active) {
        change = true
      }
    }
    if (options.reportKey) {
      if (finalFilter.reportKey !== values.reportKey) {
        change = true
      }
    }
    if (options.inventoryType) {
        if (finalFilter.inventoryType !== values.inventoryType) {
          change = true
        }
    }

    if (!change) {
      alert('No change in filters')
      setUseFilter(false)
      return
    }

    let filters = {}
    if (options.keyword) filters.keyword = values.keyword
    
    if (options.merchants) {
        filters.merchants = values.merchants
    }
    if (options.awardLevels) {
      filters.awardLevels = values.awardLevels
    }
    if (options.dateRange) {
      filters.from = values.from
      filters.to = values.to
    }
    if (options.active) {
      filters.active = values.active
    }
    if (options.reportKey) {
      filters.reportKey = values.reportKey
    }
    if (options.inventoryType) {
        filters.inventoryType = values.inventoryType
    }
    
    setFilter(filters)
    setUseFilter(true)
  }

  const onStartChange = (value) => {
    setFrom(value)
  }
  const onEndChange = (value) => {
    setTo(value)
  }

  const onChangeActive = active => {
    setActive(active)
  }

  const onInventoryTypesChange = (e) => {
    setInventoryType(e.value)
  }

  const onChangeRadio = (value) => {
    setReportKey(value)
  }

  return (
      <Row className="table-filter-form form">
        <Col md={8} lg={8} sm={8} className="table-filter-form-fields">
          <div>
            
            {options.inventoryType &&
                <>
                    <div className="table-filter-form-col table-filter-form-col2 float-filter">
                        <div className="form__form-group">
                            <span className="form__form-group-label">Show</span>
                            <div className="form__form-group-field">
                                <div className="form__form-group-row">
                                    <Select
                                        options={inventoryTypes}
                                        clearable={false}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        onChange={onInventoryTypesChange}
                                        value={inventoryTypes.find(option => option.value === inventoryType)} // Set selected value
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {options.dateRange &&
            <>
              <div className="table-filter-form-col table-filter-form-col2 float-filter">
                <div className="form__form-group">
                  <span className="form__form-group-label">From</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-row">
                      <DatePicker
                          dateFormat="MM/dd/yyyy"
                          selected={from}
                          onChange={onStartChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-filter-form-col table-filter-form-col2 float-filter">
                <div className="form__form-group">
                  <span className="form__form-group-label">To</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-row">
                      <DatePicker
                          dateFormat="MM/dd/yyyy"
                          selected={to}
                          onChange={onEndChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
            }
            {options.reportKey && (
            <div className="table-filter-form-col table-filter-form-col2 float-filter">
              <div className="form__form-group">
                {/* <span className="form__form-group-label">Report Key</span> */}
                <div className="form__form-group-field">
                  <div className="form__form-group-row">
                    <RadioButtonField
                      onChange={setReportKey}
                      radioValue="sku_value"
                      name="reportKey"
                      value={reportKey}
                      label="SKU Value"
                    />
                    <RadioButtonField
                      onChange={setReportKey}
                      radioValue="redemption_value"
                      name="reportKey"
                      value={reportKey}
                      label="Redemption Value"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="clearfix">&nbsp;</div>
            {options.merchants &&
                <div className="table-filter-form-col table-filter-form-col1 float-filter">
                    <div className="form__form-group">
                        <div className="form__form-group-field">
                            <div className="form__form-group-row">
                                <MerchantsHierarchy
                                    // defaultMerchants={options.merchants}
                                    selectedMerchants={selectedMerchants}
                                    setSelectedMerchants={setSelectedMerchants}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {options.active && (
                <>
                <div className="table-filter-form-col table-filter-form-col2 float-filter">
                    <div className="form__form-group">
                        <span className="form__form-group-label">Active Merchants:</span>
                        <div className="form__form-group-field">
                        <div className="form__form-group-row">
                            <ToggleButtonField
                                name="active"
                                label="Active Merchants:"
                                value={active}
                                onChange={onChangeActive}
                                disabled={false}
                            />
                        </div>
                        </div>
                    </div>
                </div>
                </>
            )}
          </div>
        </Col>
        <Col className="align-items-center max-height-32px pl-1">
          <Button
              onClick={() => onClickFilter()}
              className="btn btn-sm btn-primary"
              color="#ffffff"
          >Filter</Button>
          <Button
              onClick={() => onClickFilter(true)}
              className="btn btn-sm btn-primary"
              color="#ffffff"
          >Reset</Button>
          {options.exportToCsv &&
          <div>
            <span className="text-blue pointer mr-2" onClick={() => {
              download(filter)
            }}>Export to CSV</span>
            <CSVLink
                data={exportData}
                headers={exportHeaders}
                filename="report.csv"
                className="hidden"
                ref={exportLink}
                target="_blank"
            />
          </div>
          }
        </Col>
      </Row>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    organization: state.organization,
  };
};
export default connect(mapStateToProps)(SupplierRedemptionFilter);