import React, {useState} from 'react';
import {Card, CardBody, Col, Row, ButtonToolbar} from 'reactstrap';
import {useDispatch, sendFlashMessage} from "@/shared/components/flash"
import axios from 'axios'
import {Link} from 'react-router-dom'
// 
const EntrataFeedLogDetails = ({entrataFeedLog}) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const getFileType = () => {
      switch(entrataFeedLog.file_type) {
        case 1:   return 'Customers';
        case 2:   return 'Leases';
        case 3:   return 'Leads';
      }
  }


  return (
    <>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody className=''>
              <Row>
                <Col md="6" lg="6" xl="6">
                  <h3 className="mb-4">Entrata Feed Log</h3>
                </Col>

              </Row>
              <div style={{maxWidth: '500px'}}>
                <Row className='mb-4'>
                  <Col md="4" lg="4" xl="4" sm="4" className='label'>
                    ID:
                  </Col>
                  <Col md="8" lg="8" xl="8" sm="8">
                    {entrataFeedLog._id}
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md="4" lg="4" xl="4" sm="4" className='label'>
                    Program ID:
                  </Col>
                  <Col md="8" lg="8" xl="8" sm="8">
                    <Link to={`/program/view/${entrataFeedLog.program_id}`}>{entrataFeedLog.program_id}</Link>
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md="4" lg="4" xl="4" sm="4" className='label'>
                    Property ID:
                  </Col>
                  <Col md="8" lg="8" xl="8" sm="8">
                    {entrataFeedLog.entrata_property_id}
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md="4" lg="4" xl="4" sm="4" className='label'>
                    File Type:
                  </Col>
                  <Col md="8" lg="8" xl="8" sm="8">
                    {getFileType()}
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md="4" lg="4" xl="4" sm="4" className='label'>
                    Status:
                  </Col>
                  <Col md="8" lg="8" xl="8" sm="8">
                    <div style={{color: entrataFeedLog.status === 1 ? 'green' : 'red'}}>
                      {entrataFeedLog.status ? 'Success' : 'Error'}
                    </div>
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md="4" lg="4" xl="4" sm="4" className='label'>
                    Created At:
                  </Col>
                  <Col md="8" lg="8" xl="8" sm="8">
                    {new Date(entrataFeedLog.created_at).toLocaleString()}
                  </Col>
                </Row>
              </div>

              <Row className='mb-4'>
                <Col md="4" lg="4" xl="4" sm="4" className='label'>
                  response:
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>
                    <pre>{JSON.stringify(entrataFeedLog.response, null, 2)}</pre>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default EntrataFeedLogDetails
