import axios from 'axios'
import {objectToUrlParams} from '@/shared/helpers'
export const getProgram = async (organizationId, programId, params = null) => {
    let url = `/organization/${organizationId}/program/${programId}`
    let paramStr = params ? objectToUrlParams(params) : null
    url = paramStr ? `${url}?${paramStr}` : url
    const response = await axios.get(
      url
    );
    return response.data;
};