import React from 'react';
import {Card, CardBody, Col, Row} from 'reactstrap';
import ImportViewProcessedDataTable from "./ImportViewProcessedDataTable";

const ImportView = ({item, id}) => {

  return (
    <>
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="form__form-group-field">
              <div className="form__form-group" style={{maxWidth: 100}}>
                <h5>File Link</h5>
              </div>
              <div className="form__form-group">
                <a target="_blank" href={`${process.env.REACT_APP_API_STORAGE_URL}/${item.path}/uploads/${item.name}`}>Download</a>
              </div>
            </div>

            <div className="form__form-group-field">
              <div className="form__form-group" style={{maxWidth: 100}}>
                <h5>File Name</h5>
              </div>
              <div className="form__form-group">
                {item.name}
              </div>
            </div>

            <div className="form__form-group-field">
              <div className="form__form-group" style={{maxWidth: 100}}>
                <h5>File Size</h5>
              </div>
              <div className="form__form-group">
                {item.size} Kb
              </div>
            </div>

            <div className="form__form-group-field">
              <div className="form__form-group" style={{maxWidth: 100}}>
                <h5>File Rows</h5>
              </div>
              <div className="form__form-group">
                {item.rowcount}
              </div>
            </div>

            <div className="form__form-group-field">
              <div className="form__form-group" style={{maxWidth: 100}}>
                <h5>Import Type</h5>
              </div>
              <div className="form__form-group">
                {item.csv_import_type.type}
              </div>
            </div>

            <div className="form__form-group-field">
              <div className="form__form-group" style={{maxWidth: 100}}>
                <h5>Imported</h5>
              </div>
              <div className="form__form-group">
                {/*{item.rows - item.errors.length}*/}
              </div>
            </div>

            <div className="form__form-group-field">
              <div className="form__form-group" style={{maxWidth: 100}}>
                <h5>Errors</h5>
              </div>
              <div className="form__form-group">
                {/*{item.errors.length && <ErrorsTable errors={item.errors} />}*/}
              </div>
            </div>

          </CardBody>
        </Card>
      </Col>
        <Col md={12}>
          <Card>
            <CardBody>
              <ImportViewProcessedDataTable/>
            </CardBody>
          </Card>
        </Col>
    </>
  )
}

export default ImportView;
