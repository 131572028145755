import React, {useState} from 'react';
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {
  Modal,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import {ThemeProps, RTLProps} from '@/shared/prop-types/ReducerProps';
import {Field, Form} from 'react-final-form';
import axios from "axios";
import {useDispatch, flashSuccess, flashError} from "@/shared/components/flash"
import MuiButton from "@material-ui/core/Button";

const UploadModal = ({organization, isOpen, setOpen, toggle, theme, rtl, config, setTrigger}) => {
  const dispatch = useDispatch()
  const [csvFile, setCsvFile] = useState(null);

  const onSelectCsvFile = (file) => {
    setCsvFile(file)
  }

  const reset = () => {
    setCsvFile(null)
    toggle()
  }

  const onSubmit = () => {
    let data = new FormData();
    data.append('csv_import_setting_id', config.id)

    data.append('upload-file', csvFile)
    data.append('fieldsToMap', JSON.stringify(config.field_mapping))
    data.append('setups', JSON.stringify(config.setups))

    const prefix = config.csv_import_types_context.toLowerCase().substring(0, config.csv_import_types_context.length - 1)
    let url = `/organization/${organization.id}/${prefix}import`

    axios
      .post(url, data, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        //   console.log(res)
        if (res.data?.message) {
          flashSuccess(dispatch, "Import request added to queue. You will be notified when it is processed.");
          reset();
        }
      })
      .catch((error) => {
        const errors = error.response.data.errors;
        console.log(errors)
        flashError(dispatch, "Error while importing.");
      });
  }

  const validate = values => {
    return true;
  }

  return (
    <Modal className={`modal-action modal-md ${theme.className} ${rtl.direction}-support`} isOpen={isOpen}
           toggle={toggle}>
      <Card className='w-100'>
        <CardHeader tag="h3">
          Import File
          <Button className='btn float-end' close onClick={toggle}/>
        </CardHeader>
        <CardBody>
          <Form
            onSubmit={onSubmit}
            validate={validate}
            // initialValues={{
            //   user_status_id: String(user.status.id)
            // }}
          >
            {({handleSubmit, form, submitting, pristine, values}) => (
              <form className="form" onSubmit={handleSubmit}>
                <div className="form__form-group label-mb-0">

                  <Field name='upload-file'>
                    {({input, meta}) => (
                      <div className="form__form-group">
                        <div>&nbsp;</div>
                        <div className="form__form-group-field flex-column">
                          <div className="flex-row form__form-group-field">
                            <div className="flex-column">
                              <MuiButton variant="contained" component="label" style={{textTransform: 'inherit'}}>
                                Choose File
                                <input
                                  hidden
                                  type='file'
                                  id='csvFile'
                                  {...input}
                                  onChange={(e) => {
                                    onSelectCsvFile(e.target.files[0])
                                  }}
                                  value={csvFile?.name.file}
                                >
                                </input>
                              </MuiButton>
                            </div>
                            <div className="flex-column"
                                 style={{justifyContent: 'end', padding: '0px 10px', display: 'flex'}}>
                              {csvFile?.name}
                            </div>
                          </div>
                          {meta.touched && !csvFile && (
                            <span className="form__form-group-error">
                                    {meta.error}
                                </span>
                          )}
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    )}
                  </Field>

                </div>
                <div className='d-flex justify-content-end'>
                  <Button className='btn-sm mr-2' color='outline-primary' onClick={toggle}>Cancel</Button><Button
                  className='btn-sm' color='primary' type='submit'>Submit</Button>
                </div>
              </form>
            )}
          </Form>
        </CardBody>
      </Card>
    </Modal>
  )
}

UploadModal.propTypes = {
  theme: ThemeProps.isRequired,
  rtl: RTLProps.isRequired
};

export default withRouter(connect((state) => ({
  theme: state.theme,
  rtl: state.rtl,
  organization: state.organization
}))(UploadModal));