import React, {useState, useEffect, useMemo} from "react";
import {useTable, usePagination, useSortBy, useExpanded, useResizeColumns, useFlexLayout} from "react-table";
import {QueryClient, QueryClientProvider, useQuery} from 'react-query'
import {connect} from 'react-redux'
import {Link, useParams, withRouter} from 'react-router-dom'
import {reducer, useEffectToDispatch, fetchApiData, initialState, Sorting} from "@/shared/apiTableHelper"

const queryClient = new QueryClient()

const DataTable = ({organization}) => {
  const { id } = useParams()

  let [{queryPageIndex, queryPageSize, totalCount, queryPageFilter, queryPageSortBy}, dispatch] = React.useReducer(reducer, initialState);
  queryPageSize = 10000;

  const {isLoading, error, data, isSuccess} = useQuery(
    ['import', queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy],
    () => 
    fetchApiData(
      {
        url: `/organization/${organization.id}/import/view/${id}/process`,
        page: queryPageIndex,
        size: queryPageSize,
        sortby: queryPageSortBy
      }
    ),
    {
      keepPreviousData: true,
      staleTime: Infinity,
    }
  );

  let columnsTmp = [];

  if (data && data?.results && data.results.length){
    columnsTmp = Object.keys(data.results[0]).map((key, id)=>{
      if (key === 'errors'){
        return {
          Header: key,
          accessor: key,
          Cell: ({row, value}) => {
            return <div style={{fontWeight: 'bold', color: 'red'}}>{value}</div>
            },
        }
      }
      return {
        Header: key,
        accessor: key,
      }
    })
    columnsTmp.unshift(
      {
        Header: "#",
        width: 30,
        Cell: ({ row, value }) => { return Number(row.id)+1; },
      }
    );
  }

  let columns = useMemo(() => columnsTmp, [data])

  const totalPageCount = Math.ceil(totalCount / queryPageSize)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    state: {pageIndex, pageSize, sortBy}
  } = useTable({
      columns,
      data: data ? data.results : [],
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: queryPageSortBy,
      },
      manualPagination: true, // Tell the usePagination
      pageCount: data ? totalPageCount : null,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
    },
    useSortBy,
    useExpanded,
    usePagination,
    useResizeColumns,
    useFlexLayout,
  );

  useEffectToDispatch( dispatch, {pageIndex, pageSize, gotoPage, sortBy, data} );

  if (error) {
    return <p>Error: {JSON.stringify(error)}</p>;
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isSuccess)
    return (
      <>
        <div className='table react-table'>
          <table {...getTableProps()} className="table">
            <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    {column.isSorted ? <Sorting column={column}/> : ''}
                    <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? 'isResizing' : ''
                      }`}
                    />
                  </th>
                ))}
              </tr>
            ))}
            </thead>
            <tbody className="table table--bordered" {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              const subCount = (row.id.match(/\./g) || []).length
              return (
                <tr {...row.getRowProps()}>
                  {
                    row.cells.map(cell => {
                      // console.log(cell)
                      const paddingLeft = subCount * 20
                      return <td {...cell.getCellProps()}><span
                        style={cell.column.Header === '#' ? {paddingLeft: `${paddingLeft}px`} : null}>{cell.render('Cell')}</span>
                      </td>
                    })
                  }
                </tr>
              )
            })}
            </tbody>
          </table>

        </div>
      </>
    )
}

const TableWrapper = ({organization}) => {
  if (!organization) return 'Loading...'
  return (
    <QueryClientProvider client={queryClient}>
      <DataTable organization={organization}/>
    </QueryClientProvider>
  )
}
export default withRouter(connect((state) => ({
  organization: state.organization
}))(TableWrapper));
