import React from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import ImportForm from './components/ImportForm';
import { connect } from 'react-redux'

const ImportUser = ({organization}) => {

    return (
        <Container className="dashboard">
            <Row>
              <Col md={12}>
                <h3 className="page-title">Import Configurations</h3>
                <h3 className="page-subhead subhead">
                    <Link className="" to="/">Home</Link> / &nbsp;
                    <Link className="" to="/import/configurations">Import Configurations</Link></h3>
              </Col>
            </Row>
            <Row>
            <Col md={12}>
            <Card>
              <CardBody style={{display:'flex'}}>
                <ImportForm organization={organization} />
              </CardBody>
              </Card>
              </Col>
            </Row>
          </Container>
    );
}

export default withRouter(connect((state) => ({
  organization: state.organization
}))(ImportForm));
