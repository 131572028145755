import React, { useEffect, useState, useCallback } from "react";
import { useExpanded, useFlexLayout, usePagination, useResizeColumns, useSortBy, useTable } from "react-table";
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { Col, Row } from 'reactstrap';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  reducer,
  useEffectToDispatch,
  fetchApiData,
  fetchApiDataExport,
  initialState,
  Sorting
} from "@/shared/apiTableHelper";
import { Sticky, StickyContainer } from 'react-sticky';
import { dateStrToYmd, getFirstDay } from "../../../../shared/helpers";
import { tableColumns } from "./columns";
import SupplierRedemptionFilter from "./SupplierRedemptionFilter";

const queryClient = new QueryClient()

const defaultFrom = getFirstDay()
const defaultTo = new Date()

const DataTable = ({ organization, merchants }) => {
    
    const [filter, setFilter] = useState({ 
        merchants: merchants, 
        from: dateStrToYmd(defaultFrom),
        to: dateStrToYmd(defaultTo),
        active: true,
        reportKey: 'sku_value',
        inventoryType: 0,
    });
    const [useFilter, setUseFilter] = useState(false);
    const [trigger, setTrigger] = useState(0);
    const [exportData, setExportData] = useState([]);
    const [exportHeaders, setExportHeaders] = useState([]);
    const [exportToCsv, setExportToCsv] = useState(false);
    const [filterValues, setFilterValues] = useState([]);
    const [columns, setColumns] = useState([]);
    const exportLink = React.createRef();
  
    const [{ queryPageIndex, queryPageSize, totalCount, queryPageFilter, queryPageSortBy, queryTrigger }, dispatch] =
      React.useReducer(reducer, initialState);
  
    const apiUrl = `/organization/${organization.id}/report/supplier-redemption`;
    const { isLoading, error, data, isSuccess } = useQuery(
      ['', apiUrl, queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryTrigger],
      () => fetchApiData(
        {
          url: apiUrl,
          page: queryPageIndex,
          size: queryPageSize,
          filter,
          sortby: queryPageSortBy,
          trigger: queryTrigger
        }
      ),
      {
        keepPreviousData: true,
        staleTime: Infinity,
      }
    );
  
    useEffect(() => {
      if (exportToCsv) {
        if (exportLink.current) {
          setExportToCsv(false);
          exportLink.current.link.click();
        }
      }
    }, [exportLink])
  
    const download = async (filterValues) => {

      if (data.results && Array.isArray(Object.values(data.results))) {
        const csvData = ([...Object.values(data.results), data.config.total]);
        if (csvData) {
            const copiedData = csvData.map(item => ({ ...item }));
            copiedData.map(item => {
                if ( item.avg_discount_percent.toString().at(-1) != "%" )
                {    
                    item.avg_discount_percent = item.avg_discount_percent + "%";
                    item.percent_total_cost = item.percent_total_cost + "%";
                    item.total_cost_basis = "$" + item.total_cost_basis ;
                    item.total_premium = "$" + item.total_premium;
                    item.total_redemption_value = "$" + item.total_redemption_value;
                    item.percent_total_redemption_value = item.percent_total_redemption_value + "%"           
                }
            })
            setExportData(copiedData);
            //   setExportHeaders(Object.keys(Object.values(response.results)[0]));
            //   setExportHeaders(response.headers);
            setExportHeaders(data.config.columns);
            setExportToCsv(true);
        } else {
          console.error('Failed to serialize data for CSV export');
        }
      } else {
        console.error('Data is not an array:', data.results);
      }
    };
  
    const updateColumns = useCallback((data) => {
      if (data) {
        setColumns(tableColumns(data.config));
      }
    }, []);
  
  
    useEffect(() => {
      updateColumns(data);
    }, [updateColumns, data]);
    
    const totalPageCount = Math.ceil(totalCount / queryPageSize)
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      footerGroups,
      rows,
      prepareRow,
      page,
      pageCount,
      pageOptions,
      gotoPage,
      previousPage,
      canPreviousPage,
      nextPage,
      canNextPage,
      setPageSize,
      state: { pageIndex, pageSize, sortBy }
    } = useTable({
      columns: columns,
      data: data ?  Object.values(data.results) : [], //data: data ? data.results : [],
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: queryPageSortBy,
      },
      manualPagination: true, // Tell the usePagination
      pageCount: data ? totalPageCount : null,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
      disableResizing: true,
      autoResetHiddenColumns: false
    },
      useSortBy,
      useExpanded,
      usePagination,
      useResizeColumns,
      useFlexLayout,
    );
  
    const manualPageSize = []
    useEffectToDispatch(dispatch, { pageIndex, pageSize, gotoPage, sortBy, filter, data, useFilter, trigger });
  
    if (isLoading) {
      return <p>Loading...</p>;
    }
  
    if (error) {
      return <p>Error: {JSON.stringify(error)}</p>;
    }
  
    if (isSuccess)
      return (
        <StickyContainer>
          <div style={{ width: '100%', overflowX: 'auto', margin: 'auto' }}>
            <div className='table react-table report-table' style={{ minHeight: '10px' }}>
              <div className="action-panel" style={{ paddingBottom: '35px' }}>
                <Row className="mx-0">
                  <Col>
                    <SupplierRedemptionFilter
                        filter={filter} setFilter={setFilter} useFilter={useFilter} setUseFilter={setUseFilter}
                        exportData={exportData} exportLink={exportLink} exportHeaders={exportHeaders}
                        download={download}/>
                  </Col>
                </Row>
                <div style={{ clear: 'both' }}>&nbsp;</div>
              </div>
            </div>
            {isLoading &&
              <p>Loading...</p>
            }
            {isSuccess &&
              <table {...getTableProps()} className="table text-center">
  
                <Sticky topOffset={90}>
                  {({ style }) => (
                    <thead style={{ ...style, top: '60px', zIndex: 99999 }}>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                              {column.render('Header')}
                              {column.isSorted ? <Sorting column={column} /> : ''}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                  )}
                </Sticky>
                <tbody className="table table--bordered" {...getTableBodyProps()}>
                  {page.map(row => {
                    prepareRow(row);
                    const subCount = (row.id.match(/\./g) || []).length
                    return (
                      <tr {...row.getRowProps()}>
                        {
                          row.cells.map(cell => {
                            // console.log(cell)
                            const paddingLeft = subCount * 20
                            return <td {...cell.getCellProps({
                              className: cell.column.className,
                              style: {
                                textAlign: cell.column.noAlignRight ? '' : 'right',
                              }
                            })}><span
                              style={cell.column.Header === '#' ? { paddingLeft: `${paddingLeft}px` } : null}>{cell.render('Cell')}</span>
                            </td>
                          })
                        }
                      </tr>
                    )
                  })}
                </tbody>
                <tfoot>
                  {footerGroups.map((footerGroup) => (
                    <tr {...footerGroup.getFooterGroupProps()}>
                      {footerGroup.headers.map(column => (
                        <th {...column.getFooterProps()}>{column.render('Footer')}</th>
                      ))}
                    </tr>
                  ))}
                </tfoot>
              </table>
            }
          </div>
        </StickyContainer>
      )
  }

const TableWrapper = ({ organization, merchants }) => {
if (!organization) return 'Loading...'
return (
    <QueryClientProvider client={queryClient}>
        <DataTable organization={organization} merchants={merchants} />
    </QueryClientProvider>
)
}

export default withRouter(connect((state) => ({
    organization: state.organization
}))(TableWrapper));
