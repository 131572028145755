import { Validators } from '@lemoncode/fonk';
// import { isTrue } from '@lemoncode/fonk-is-true-validator';
Validators.required.setErrorMessage("This field is required");
// isTrue.setErrorMessage("This field is required");

const validationSchema = {
    field: {
        host: [Validators.required.validator],
        user_name: [Validators.required.validator],
        user_password: [Validators.required.validator],
        program_id: [Validators.required.validator],
        local_directory: [Validators.required.validator],
        remote_directory: [Validators.required.validator],
        status: [Validators.required.validator],
        source_type: [Validators.required.validator],
    }
}
export default validationSchema;
