import {SOURCE_OPTIONS} from "./SourceOptions";
import { Link } from 'react-router-dom';

export const IMPORT_CONFIGURATION_COLUMNS = [
    {
        Header: "#",
        accessor: "id",
        Footer:"#",
        disableFilters:true,
        disableGlobalFilters:true,
        width: 60
    },
    {
      Header: "Organization Id",
      accessor: "organization_id"
    },
    {
        Header: "Program",
        Cell: ({ row, value }) => {
            var program='Coming from file';
            if(row.original.setups.CSVProgramRequest?.program_id){
                program='Program::'+row.original.setups.CSVProgramRequest?.program_id;
            }
            return program;
        }
    },
    {
        Header: "Import Type",
        accessor: "csv_import_types_name"
    },
    {
        Header: "Auto Import",
        Cell: ({ row, value }) => {
            var cronjob='Inactive';
            if(row.original.setups.CsvImportSourceRequest?.id){
                cronjob='Active';
            }
            return cronjob;
        }
    },
    {
        Header: "Import Source",
        Cell: ({ row, value }) =>  {
            var source = 'Manual';
            if(row.original.setups.CsvImportSourceRequest?.id){
                source = row.original.setups.CsvImportSourceRequest?.id;
            }
            return source;
        }
    },
    {
      Header: "Action",
      accessor: "",
      Cell: ({ row, value }) =>
          <>
            <Link to={`/import/configurations/${row.original.id}`}>Edit</Link>
             &nbsp; | &nbsp;
            <Link to={`#`}>Import</Link>
          </>
    }
]
