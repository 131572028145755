import { Link } from 'react-router-dom';
import {useParams} from 'react-router-dom'


export const ImportSourceFilesListColumns = [
    {
      Header: "File Name",
      accessor: "file_name"
    }
]
